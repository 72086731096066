.form-field-head {
  color: var(--h_text);
  font-family: var(--font);
  font-size: var(--form_field);
  margin: 0 0 15px;
  font-style: normal;
  font-weight: 700;
}

.input-outlined {
  border: 1px solid var(--g_text);
  color: var(--g_text);
  font-family: var(--font);
  font-size: var(--input_t);
  width: 100%;
  padding: 14px 17px;
  font-style: normal;
  font-weight: 600;
}

/*# sourceMappingURL=index.2310337d.css.map */
