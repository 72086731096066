.form-field-head{
    color: var(--h_text);
    font-family: var(--font);
    font-size: var(--form_field);
    font-style: normal;
    font-weight: 700;
    margin: 0 0 15px 0;
}
/* input */
/* .MuiOutlinedInput-root{
    border-radius: var(--br_r)!important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    font: -webkit-control!important;
    padding: 14px 17px!important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 15px!important;
    top: -2px!important;
} */
.input-outlined{
    width: 100%;
    padding: 14px 17px;
    border: 1px solid var(--g_text);

    color: var(--g_text);
    font-family: var(--font);
    font-size: var(--input_t);
    font-style: normal;
    font-weight: 600;
}
/* input ends */